@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&family=Space+Mono:wght@700&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik',sans-serif;
  background-color: hsl(258, 60%,98%);
}

html {scroll-behavior: smooth;}
